import { Injectable } from '@angular/core';
import { ICustomerData } from 'src/app/shared/models/model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  storeCustomer(customer: ICustomerData){
    if(!sessionStorage.getItem('customer')) sessionStorage.setItem('customer', JSON.stringify(customer))
  }

  getStoredCustomer(): ICustomerData{
   let customer = sessionStorage.getItem('customer')
   return customer ? JSON.parse(customer) : null
  }

  deletedStoredCustomer(){
    sessionStorage.removeItem('customer')
  }

  deleteStorageItem(item: string) {
    sessionStorage.removeItem(item);
  }

  setStorage(name: string ,data: any): void {
    if (data) sessionStorage.setItem(name, JSON.stringify(data));
  }

  getStorage(name: string) {
   let storage = sessionStorage.getItem(name);
   return storage ? JSON.parse(storage) : null
  }

  removeStorage(name: string): void {
    sessionStorage.removeItem(name);
  }

  removeAllStorage(): void {
    sessionStorage.clear();
  }
  
}
