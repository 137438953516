export const environment = {
  production: true,
  urlBase: 'https://fntdeu2mandp02-dhegcbbfh5fxf3fe.a03.azurefd.net/api/',
  ipApi: 'https://ipapi.co/json/',
  ipStack: 'https://api.ipstack.com/check?access_key=f11950c2aded6a3e193d2c1ed072f668', // Change ipInfo for ipStack
  urlTermsConditions: 'https://staceu2mandd01.blob.core.windows.net/public-documents/BCPMandadito-TOS-240904.pdf',
  urlTermsConditionsPrivacyPolicy:'https://staceu2mandd01.blob.core.windows.net/public-documents/BCPMandadito-TOS-240904+pp.pdf',
  inMaintenance: false,
  gtmID: 'GTM-5MMMVFD',
  trustedIps: ['2001:1388:18:8337:117c:b6e7:f95e:67e3', '163.116.229.32', '163.116.229.23'],
  ocpApimSubscriptionKey: '1386fc3cafa640c8b74422953b3a7c9c',
  sentryEnvironment:'production',
  recaptcha: {
    siteKey: '6LeY9HkpAAAAABrgj3fWXlca8m0gJcBRITpycljP',
  },
  urlBaseBlob: 'https://staceu2mandp01.blob.core.windows.net',
  paypal: 'https://www.paypal.com/sdk/js?client-id=AereCQxKNFbOHMMHmgFeviQQ4K4c2Rd7UrJStHL6FAWHClEmno6U0WbmbPIWMuAz9X8znHecravHVGDi&disable-funding=paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo&currency=USD'
};
