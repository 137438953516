import { CommonModule, DecimalPipe } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { LandingComponent } from "./landing/landing.component";
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import { BcpFormmodule } from "@bcp/ng-core-v4/forms";
import { SafePipe } from "../core/pipes/safe.pipe";
import { RouterModule } from "@angular/router";
import { MaintenanceWindowComponent } from "./maintenance-window/maintenance-window.component";
import { RedirectComponent } from "./redirect/redirect.component";
import { RecaptchaModule } from "ng-recaptcha";
import { HeadbandComponent } from "./landing/components/headband/headband.component";
import { ModalScheduleComponent } from "./landing/components/popup-landing/popup-landing.component";
import { LayerPopupLandingComponent } from "./landing/components/layer-popup-landing/layer-popup-landing.component";
import { BcpComponent } from "./landing/components/bcp/bcp.component";
import { WhatsappComponent } from "./landing/components/whatsapp/whatsapp.component";
import { CurrencyConversionCalculatorComponent } from "./landing/components/currency-conversion-calculator/currency-conversion-calculator.component";
import { SearchByDniComponent } from "./landing/components/currency-conversion-calculator/search-by-dni/search-by-dni.component";

const elements = [
  LandingComponent,
  MaintenanceWindowComponent,
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    ...elements,
    RedirectComponent,
    HeadbandComponent,
    ModalScheduleComponent,
    LayerPopupLandingComponent,
    BcpComponent,
    WhatsappComponent,
    CurrencyConversionCalculatorComponent,
    SearchByDniComponent,
  ],
  exports: [...elements],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    BcpFormmodule,
    RouterModule,
    RecaptchaModule,
  ],
  providers: [SafePipe, DecimalPipe],
})
export class ViewsModule {}
