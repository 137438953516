<form class="form-main" [formGroup]="formReceiver" (ngSubmit)="searchByDNI()">
<div class="calculator"> 
    <div class="calculator-dni" [ngClass]="showcomplete ? 'modeenabled' : 'modedisabled'">
        <div *ngIf="showCaptcha" class="recaptcha">
            <re-captcha (resolved)="resolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
        </div>
        <div *ngIf="!showCaptcha" class="dni-input">
            <label class="dni-label" for="dni-input">Encuentra tus beneficios ingresando tu DNI</label>
            <div class="dni-input">
                <app-input
                    [label]="getError('documentNumber') ? 'Ingresa tu N° de DNI *' : ''"
                    type="text"
                    [maxlength]="8"
                    [required]="true"
                    placeholder="Nº de DNI peruano"
                    (ctrlKeyPress)="keyEnter($event)"
                    (ctrlChange)="onChangeInput()"
                    [state]="getError('documentNumber')"
                    [message]="getError('documentNumber') ? getMessage('documentNumber') : ''"
                    formControlName="documentNumber">
                </app-input>
                <div class="select-dni">
                    <app-button shape="rectangle" full-width="true" size="lg" type="submit">
                        <bcp-spinner *ngIf="showSpinner" theme="dark" size="md"> </bcp-spinner>
                        <span *ngIf="!showSpinner">Cotizar</span>
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
