import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './views/landing/landing.component';
import { isAuthenticatedGuard } from './shared/guards/is-authenticated.guard';
import { MaintenanceGuard } from './shared/guards/maintenance.guard';
import { RedirectComponent } from './views/redirect/redirect.component';
import { ReloadComponent } from './shared/components/reload/reload.component';
import { DeploymentWindowGuard } from './shared/guards/deployment-window.guard';
import { MaintenanceWindowComponent } from './views/maintenance-window/maintenance-window.component';

const routes: Routes = [
  {
    path: '', canActivateChild: [MaintenanceGuard], children: [
      { path: '', component: LandingComponent, pathMatch: 'full' },

      { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule) },
      { path: 'iniciar-sesion', redirectTo: 'auth/iniciar-sesion' },

      { path: 'registro', loadChildren: () => import('./views/registration/registration.module').then(m => m.RegistrationModule) },
      {
        path: 'home',
        canActivate: [isAuthenticatedGuard, DeploymentWindowGuard],
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'mandadito',
        canActivate: [isAuthenticatedGuard],
        component: ReloadComponent,
        pathMatch: 'full'
      },
      {
        path: 'terminos-y-condiciones',
        loadChildren: () => import('./views/terms-and-conditions-layout/terms-and-conditions-layout.module').then(m => m.TermsAndConditionsLayoutModule),
      },
      {
        path: 'public',
        loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule),
      },
    ]
  },
  {
    path: 'mantenimiento',
    component: MaintenanceWindowComponent,
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'privacypolicy',
    component: RedirectComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
