import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { ASSETS, ErrorImageType } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { SafePipe } from 'src/app/core/pipes/safe.pipe';
import { GtmService } from 'src/app/core/services/gtm.service';
import { DocumentTypes } from '../../models/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnChanges{
  constructor(private activatedRoute: ActivatedRoute) { }

  @Input() data = {
    number: 1,
    title: '',
    permitFormats: {
      title: 'Sube una imagen JPG o PNG',
      titleMobile: '',
      formats: []
    },
    maxSize: 10,
    preview: false
  }

  @Input()
  selectedDocument: string

  @Output() documentEvent = new EventEmitter<any>();

  @ViewChild('inputFile', { static: true }) inputFile: ElementRef;

  private safePipe = inject(SafePipe);
  private gtmService = inject(GtmService);

  image = {
    file: null,
    url: null,
    name: null,
    status: null
  };

  urlImagePreview = null;
  showDocument = false;
  errorImageType: ErrorImageType;
  ErrorImageType = ErrorImageType;
  documentTypes = DocumentTypes;
  imgSpotCamera: string = ASSETS.SPOT_CAMERA;
  imgSpotCardAdd: string = ASSETS.SPOT_CARDS_ADD;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedDocument']){
      this.removeImage()
    }
  }

  removeImage(): void {
    this.imageToRemove();
    this.showDocument = false;
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_subir_otra_foto',
      pantalla: 'Registro2'
    };
    this.gtmService.sendEvent(event);
  }

  private imageToRemove(): void {
    
    this.urlImagePreview = null;
    this.inputFile.nativeElement.value = '';
    this.image = {
      file: null,
      url: null,
      name: null,
      status: null
    };

    this.documentEvent.emit(null);
  }

  onFileChange(event: any) {  
    const [file] =event.target.files;
    if (file) {
      this.imageToRemove();
      setTimeout(() => {
        this.image.url = this.safePipe.transform(URL.createObjectURL(file), 'resourceUrl');
        this.image.file = file;
        this.image.name = file.name;
        this.image.status = this.validateFile(file);
        file.status = this.image.status;
        this.documentEvent.emit(file);

        if (this.data.preview) {
          this.urlImagePreview = `url('${this.image.url}')`;
          this.showDocument = true;
        }
      }, 0);
    }
  }

  validateFile(file) {
    let status = 'success';
    this.errorImageType = null;
    if (!this.data.permitFormats.formats.includes(file.type)) {
     this.errorImageType = ErrorImageType.FILE_TYPE;
     status = 'error';
    } else if (file.size > this.data.maxSize * 1024 * 1024) {
     this.errorImageType = ErrorImageType.FILE_SIZE;
     status = 'error';
    }

    return status;
  }

  currentRoute: string;

  photoIsOk(): void {
    this.currentRoute = this.activatedRoute.snapshot.url.map(segment => segment.path).join('/');

    if(this.currentRoute == 'completar-registro-foto'){
      const event: GtmEvent = {
        event: 'virtualEvent',
        accion: 'Home_fotocumple​',
        pantalla: 'Home'
      };
      this.gtmService.sendEvent(event);
    }
    else{
      const event: GtmEvent = {
        event: 'virtualEvent',
        accion: 'Clic_foto_cumple',
        pantalla: 'Registro2'
      };
      this.gtmService.sendEvent(event);
    }
    this.showDocument = false;

  }

  triggerFileInput(): void {
    let accion = '';
    if(this.selectedDocument ===  DocumentTypes.DriverLicense){
        accion = `clic_Registro2_DL${ this.data.number }`;
    }
    if (this.selectedDocument === DocumentTypes.DNI) {
        accion = `clic_Registro2_DNI${ this.data.number }`;
    }
      
    const event: GtmEvent = {
        event: 'virtualEvent',
        accion: accion,
        pantalla: 'Registro2'
    };
    this.gtmService.sendEvent(event);

    this.inputFile.nativeElement.click();
  }
}
