<app-modal [size]="'sm'" *ngIf="modalName === modals.MAX_MONTH">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">Has alcanzado el máximo de<br>envíos este mes</h3>
        <p class="mt-3 flexo-regular font-size-14 line-height-20 text-center">Solo puedes enviar <span class="flexo-demi">4 Mandaditos por mes</span>, regresa el primer día del próximo mes.</p>

        <ng-container *ngIf="!modal?.isRepeatingMandadito">
            <div class="extra-button-size mx-auto mt-3">
                <bcp-button *ngIf="customer?.hasOperationsCompleted" shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToHistory()">Ver mi historial de Mandaditos</bcp-button>
            </div>
            <div class="mt-3 text-center">
                <bcp-button [shape]="!customer?.hasOperationsCompleted ? 'rectangle':'text'" [size]="!customer?.hasOperationsCompleted ? 'lg':'md'" tier="primary" (click)="closeSession()">Volver a Mandaditobcp.com.pe</bcp-button>
            </div>
        </ng-container>
        <div class="extra-button-size mx-auto mt-3" *ngIf="modal?.isRepeatingMandadito">
            <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="closeModal()">Volver</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.MAX_TODAY">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">Has alcanzado el monto<br>máximo por hoy</h3>
        <p class="mt-3 flexo-regular font-size-14 line-height-20 text-center">Solo puedes enviar <span class="flexo-demi">{{modal.maxAmountMandadito}} dólares por día</span>, regresa mañana para enviar otro Mandadito.</p>

        <div class="extra-button-size mx-auto mt-3" *ngIf="!modal.hasOperationsCompleted">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="closeSession()">Volver a Mandaditobcp.com.pe</bcp-button>
        </div>

        <div class="extra-button-size mx-auto mt-3" *ngIf="modal.hasOperationsCompleted">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToHistory()">Ver mi historial de Mandaditos</bcp-button>
        </div>
        <div class="mt-3 text-center" *ngIf="modal.hasOperationsCompleted">
            <bcp-button shape="text" tier="primary" (click)="closeSession()">Volver a Mandaditobcp.com.pe</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.ACCESS_TIME">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img width="147" class="mx-auto" src="/assets/images/sleeping-piggy.svg" alt="pig" />
            <h3 class="mt-4 flexo-demi font-size-md-24 font-size-20 line-height-32 text-center color-primary_700 mb-2">En este horario no puedes<br />enviar Mandaditos</h3>
            <p class="mt-1 flexo font-size-md-16 font-size-14 line-height-24 text-center mb-2">Nuestro horario de env&iacute;os es:</p>
            <ul class="modal-extended-hours-req">
                <li>Lunes a viernes durante todo el d&iacute;a</li>
                <li>S&aacute;bados hasta la 2:00 p.m. EST</li>
            </ul>

            <ng-container *ngIf="!modal?.isRepeatingMandadito">
                <div class="extra-button-size mx-auto mt-3" *ngIf="modal?.hasOperationsValid">
                    <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToHistory()">Ver mi historial de Mandaditos</bcp-button>
                </div>
                <div class="mt-3 text-center">
                    <bcp-button [shape]="modal?.hasOperationsValid ? 'text': 'rectangle'" tier="primary" (click)="closeSession()">Volver a Mandaditobcp.com.pe</bcp-button>
                </div>
            </ng-container>
            <div class="extra-button-size mx-auto mt-3" *ngIf="modal?.isRepeatingMandadito">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="closeModal()">Entendido</bcp-button>
            </div>
        </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.REJECTED">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="error-modal-header">Por ahora no podemos atenderte en Mandadito</h3>
        <p class="error-modal-subheader">Revisa tu correo para mas información:</p>
        <span class="flexo-demi font-size-18 text-center">{{ modal.userEmail }}</span>
        <div class="mt-3 text-center font-size-16">
            <bcp-button shape="rectangle" size="lg" class="mt-3" (click)="closeSession()">Volver a Mandaditobcp.com.pe</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.RECOVERY">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="error-modal-header">Tuvimos un problema con la información de tu registro</h3>
        <p class="error-modal-subheader">Para conocer como resolverlo revisa tu correo:</p>
        <span class="flexo-demi font-size-18 text-center">{{ modal.userEmail }}</span>
        <div class="mt-3 text-center font-size-16">
            <bcp-button shape="rectangle" size="lg" class="mt-3" (click)="closeSession()">Volver a Mandaditobcp.com.pe</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.RELOAD">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="text-center mt-3">Al parecer hubo un problema</h3>
        <p class="px-2 font-size-14 font-size-md-16 text-center mt-2">No pudimos cargar la información. Por favor, intenta nuevamente en unos segundos.</p>
        <div class="mt-3 text-center font-size-16">
            <bcp-button shape="text" tier="primary" (click)="reloadPage()">
                Volver a cargar
                <bcp-icon accessible-name="" accessible-title="" name="turn-r" slot="end"></bcp-icon>
              </bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.TIMESESSION">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img src="/assets/images/sleeping-piggy.svg" width="138" class="d-block mx-auto" alt="sesión vencida">
            <h3 class="mt-4 flexo-demi font-size-20 font-size-md-24 line-height-28 line-height-md-32 text-center color-primary_700 break-word pl-24 pr-24">Se ha cerrado la sesión</h3>
            <p class="mt-3 font-size-14 text-center font-size-16 line-height-20 line-height-24 text-center break-word pl-24 pr-20 line-height-20">Para seguir usando Mandadito inicia sesión nuevamente</p>
            <div class="mt-3 px-3 text-center d-flex flex-column font-size-16">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToLogin()">Iniciar sesión de nuevo</bcp-button>
            </div>
        </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.PROBLEM">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="text-center mt-3">Ocurrió un problema</h3>
        <p class=" font-size-14 font-size-md-16 text-center mt-2">Por favor, intenta nuevamente.</p>
        <div class="mt-3 button">
            <bcp-button shape="rectangle" size="lg" full-width="true" (click)="modalName = ''">
                De acuerdo
              </bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.PASSWORD">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img src="/assets/images/spot-heroes-s-sh-lock.svg" width="138" class="d-block mx-auto" alt="sesión vencida">
            <h3 class="mt-4 flexo-demi font-size-20 font-size-md-24 line-height-28 line-height-md-32 text-center color-primary_700 break-word pl-24 pr-24">Te ayudamos a recuperar tu contraseña</h3>
            <p class="mt-3 font-size-14 text-center font-size-16 line-height-20 line-height-24 text-center break-word pl-10 pr-10 line-height-20">
                <strong class="mt-3 CharacterMDtextC">Escríbenos a nuestro WhatsApp</strong> y nos contactaremos contigo para que puedas crear una nueva contraseña
            </p>
            <div class="mt-3 px-3 text-center d-flex flex-column font-size-16">
                <a class="d-flex align-items-center" [href]="whatsappUrl" rel="noopener" target="_blank"
                    (click)="sendEvent()">
                    <img src="/assets/images/landing/whatsapp-icon.svg" width="20" height="20" alt="Whatsapp">
                    <span class="flexo-demi text-light font-size-16">Escríbenos</span>
                </a>
            </div>
            <div class="mt-3 px-3 text-center d-flex flex-column font-size-16">
                <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="goToLogin()">Volver</bcp-button>
            </div>
        </div>
    </section>
</app-modal>
