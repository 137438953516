<div class="input-el">
    <div class="form-group input-el__content">
        <input
            #inputEl
            [id]="id"
            class="form-control flexo-demi"
            [ngClass]="{'error': state === 'error'}"
            [attr.maxLength]="getMaxLength()"
            [type]="type"
            [attr.aria-describedby]="idHelp"
            [placeholder]="placeholder"
            [maxlength]="maxlength"
            (focus)="getFocusEvent($event)"
            (keypress)="getKeypressEvent($event)"
            (input)="getChangeEvent($event)"
            [formControl]="formControl"
            autocomplete="off">
        <label class="flexo-demi" 
            [ngClass]="{'active': active, 'error': state === 'error'}" [for]="id">{{label}}</label>
    </div>    
    <small
        [id]="idHelp"
        class="form-text text-muted"
        [ngClass]="{'error': state === 'error'}">{{message}}</small>
</div>