export const PATHS = {
  LANDING: '/',
  RELOAD_MANDADITO: '/mandadito',
  MAINTENANCE: '/mantenimiento',

  LOGIN: '/auth/iniciar-sesion',
  RECOVER: '/auth/recuperar-clave',
  PASSWOR_UPDATED: '/auth/clave-actualizada',
  LIMIT: '/auth/limite',
  EXCEEDED: '/auth/excedido',
  REVIEWING_DATA: '/auth/revisando-datos',

  REGISTER: '/registro',
  COMPLETE_REGISTER: '/registro/completar-registro',
  COMPLETE_REGISTER_PHOTO: '/registro/completar-registro-foto',
  UPDATE_DATA: '/registro/actualizar-datos',
  SUCCESS_REGISTER: '/registro/registro-exitoso',

  NEW_MANDADITO: '/home/nuevo-mandadito',
  REQUEST_SUCCESS: '/home/solicitud-enviada',
  HISTORICAL: '/home/mis-mandaditos',
  MY_DATA: '/home/mis-datos',
  REFERRALS:  '/home/referidos',

  TERMS: '/terminos-y-condiciones',

  FAQs: '/public/preguntas-frecuentes',
  OTHER_STATE: '/public/otro-estado',
  DEPLOYMENT: '/public/despliegue',
}

export enum MANDADITO_STEPS {
  AMOUNT = 'AMOUNT',
  FAVORITE = 'FAVORITE',
  RECEIVER = 'RECEIVER',
  RESUME = 'RESUME',
  PAYPAL = 'PAYPAL'
}

export enum TYPE {
  CARD,
  INPUT
}

export enum API_CODES {
  OK = 'OK',
  API_OPER_ERROR_EXPIRED_SESSION = 'API_OPER_ERROR_EXPIRED_SESSION', //AGREGANDO TIEMPO DE SESION PARA EL BUG
  API_OPER_ERROR_MAX_QUANTITY_MONTH = 'API_OPER_ERROR_MAX_QUANTITY_MONTH',
  API_OPER_ERROR_MAX_AMOUNT_DAY = 'API_OPER_ERROR_MAX_AMOUNT_DAY',
  API_OPER_ERROR_ACCESS_TIME = 'API_OPER_ERROR_ACCESS_TIME',
  API_OPER_DEPLOY_MAINTENANCE = 'API_OPER_ERROR_DEPLOY_MAINTENANCE',
  CODE_401 = 401,
}

export enum AuthStatus {
  checking = 'checking',
  authenticated = 'Aprobado',
  nonAuthenticated = 'nonAuthenticated',
  rejected = 'Rechazado',
  Recovering = 'Recuperación',
  Reviewing = 'En revisión',
}

export enum AuthRecoverType {
  BLANK = '',
  justDni = 'Sólo DNI',
  justPersonalData = 'Sólo Datos personales',
  dniAndPersonalData = 'DNI y Datos personales',
  Others = 'Otros',
}

const JUST_LETTERS = 'Ingresa solo letras';
const JUST_NUMBERS = 'Ingresa solo números';
const REQUIRED = 'Ingrese el dato';
const EMAIL = 'Ingrese un correo electrónico válido';
const REPEAT_EMAIL = 'Los correos no coinciden';
const CANTITY_DIGITS_DNI = 'Ingresa 8 caracteres';
const CANTITY_DIGITS_ACCOUNT = 'Ingresa 14 dígitos sin espacios ni guiones';
const BCP_DOLLARS_ACCOUNT_TYPE = 'Debes ingresar una cuenta dólares BCP';
const ACCEPT_TERMS = 'Debe aceptar los términos y condiciones';
const ACCEPT_CLAUSES = 'Debe aceptar la clausula de usos adicionales';
const FLORIDA_VALUE = 'Por ahora solo puedes usar Mandadito desde Florida';
const USA_VALUE = 'En Mandadito solo puedes enviar dinero desde USA';
const MIN_LENGTH = 'Ingrese al menos NUM_LENGTH números';
const INVALID_PASSWORD = 'Ingresa una contraseña segura';
const REPEAT_PASSWORD = 'Las contraseñas no coinciden';

export const UPDATE_DATA_STORAGE = 'update-data';

export const inputs = {
  accountNumber: {
    required: REQUIRED,
    pattern: BCP_DOLLARS_ACCOUNT_TYPE,
    minlength: CANTITY_DIGITS_ACCOUNT,
    maxlength: CANTITY_DIGITS_ACCOUNT
  },
  firstName: {
    required: REQUIRED,
    pattern: JUST_LETTERS
  },
  secondName: {
    pattern: JUST_LETTERS
  },
  firstSurname: {
    required: REQUIRED,
    pattern: JUST_LETTERS
  },
  secondSurname: {
    required: REQUIRED,
    pattern: JUST_LETTERS
  },
  firstLastname: {
    required: REQUIRED,
    pattern: JUST_LETTERS
  },
  secondLastname: {
    required: REQUIRED,
    pattern: JUST_LETTERS
  },
  email: {
    required: REQUIRED,
    pattern: EMAIL
  },
  emailRepeat: {
    required: REQUIRED,
    emailMatchError: REPEAT_EMAIL
  },
  dni: {
    required: REQUIRED,
    pattern: JUST_NUMBERS,
    minlength: CANTITY_DIGITS_DNI,
    maxlength: CANTITY_DIGITS_DNI,
  },
  documentNumber: {
    required: REQUIRED,
    pattern: JUST_NUMBERS,
    minlength: CANTITY_DIGITS_DNI,
    maxlength: CANTITY_DIGITS_DNI,
  },
  phoneNumber: {
    required: REQUIRED,
    pattern: JUST_NUMBERS,
    minlength: MIN_LENGTH.replace('NUM_LENGTH', '8'),
    mustBeJustNumbers: JUST_NUMBERS,
  },
  password: {
    required: REQUIRED,
    invalid: INVALID_PASSWORD
  },
  terms: {
    required: ACCEPT_TERMS
  },
  clauses: {
    required: ACCEPT_CLAUSES
  },
  country: {
    mustBeEqualToValue: USA_VALUE
  },
  state: {
    mustBeEqualToValue:  FLORIDA_VALUE
  },
  repeatPassword: {
    required: REQUIRED,
    passwordMatchError: REPEAT_PASSWORD
  }
}

export enum CustomerStatus  {
  UNKNOWN = '',
  APPROVED = 'Aprobado',
  REJECTED = 'Rechazado',
  RECOVERY = 'Recuperación'
}

export enum ErrorImageType {
  FILE_SIZE = 'FILE_SIZE',
  FILE_TYPE = 'FILE_TYPE'
}

export const ModalTypes = {
  MAX_MONTH: 'showMaxMonthModal',
  MAX_TODAY: 'showMaxTodayModal',
  ACCESS_TIME: 'showModalAccessTime',
  REJECTED: 'showModalReject',
  RECOVERY: 'showModalRecovery',
  RELOAD: 'showModalReload',
  PROBLEM: 'showModalProblem',
  TIMESESSION: 'showModalTimeSession',
  PASSWORD: 'showModalPassword',
  EMPTY: '',
}

export const IP_PROVIDER = {
  IP_API: 'ipApi',
  IP_STACK: 'ipStack',
}

export const MandaditoStatus = {
  InProgress: 'En proceso',
  Sent: 'Enviado',
  Rejected: 'Rechazado'
}


export enum INFOBIP_STATUS {
  LOADING = 'loading',
  INFOBIP_TTL_EXPIRED = 'ttl_expired',
  REQUESTAGAIN = 'requestAgain',
  VERIFIED = 'verified',
  INFOBIP_WRONG_PIN = 'wrong_pin',
  INFOBIP_NO_MORE_PIN_ATTEMPTS = 'no_more_pin_attempts',
  INFOBIP_THROTTLE_EXCEPTION = 'throttle_exception',
}
export enum LOGIN_ERROR {
  USER_PASSWORD_INCORRECT = 'USER_PASSWORD_INCORRECT',
  USER_SIGNIN_TRIES_LIMIT_ERROR = 'USER_SIGNIN_TRIES_LIMIT_ERROR',
  USER_SIGNIN_TRIES_EXCEED_ERROR = 'USER_SIGNIN_TRIES_EXCEED_ERROR',
  ERROR_SIGNIN_SESSION = 'ERROR_SIGNIN_SESSION',
}

export const ASSETS = {
  SPOT_ALERT_HAND: '/assets/images/spot_alert_hand_d_l.svg',
  SPOT_ALERT_GROUP: '/assets/images/group-21.svg',
  SPOT_SCAN_DOCUMENT: '/assets/images/spot-heroes-s-sh-scan-dni-woman-l.svg',
  SPOT_HAND_LOCK: '/assets/images/spot-hand-lock.svg',
  SPOT_REGISTER: '/assets/images/illust-default.svg',
  SPOT_BANNER_GIFT: '/assets/images/spot_gift-orange.svg',
  SPOT_CARDS_ADD: '/assets/images/cards-add-file-1-upload.svg',
  SPOT_CAMERA: '/assets/images/sp_camera_d.svg',
  IMG_LOGO_CHIP: '/assets/images/logo-mandadito-chip.svg',
  IMG_CHECK_VALID: '/assets/images/check-round-valid.svg',
  IMG_ICON_CLOSE_GREEN: '/assets/images/icon-close-green.svg',
  IMG_ICON_INFO: '/assets/images/icon-info.svg',
  IMG_CHECK_ERROR: '/assets/images/icon-alert.svg',
  IMG_CHECK_SUCCESS: '/assets/images/icon-success.svg',
}

export const FORM_MESSAGE = {
  DNI_REGISTER: 'El DNI ya se encuentra registrado',
  DNI_REGISTER_INCOMPLETE: 'El DNI tiene un registro en proceso',
  DNI_INVALID: 'Debes de ingresar 8 d\u00EDgitos',
}

export const TEXT = {
  REGISTER: `Reg\u00EDstrate y env\u00EDa dinero a cualquier parte del Per\u00FA`,
  REGISTER_SUBTITLE: `Reg\u00EDstrate con tu DNI o Driver's license`,
  REGISTER_BTN: 'Ingresar a Mandadito',
  REGISTER_SUCCESS: '¡Gracias por registrarte!',
  COMPLETE_REGISTER_BTN: 'Mandar dinero ahora',
  COMPLETE_REGISTER_SUBTITLE: 'Sube una foto de tu documento',
  COMPLETE_REGISTER: 'Completa tu registro',
  COMPLETE_REGISTER_SUCCESS: '¡Completaste tu registro!',
  COMPLETE_REGISTER_SUCCESS_PHOTO: 'Tus fotos se subieron con éxito, ya puedes solicitar tu primer envío',
  MSG_INCOMPLETE_FORM: 'Te falta completar o corregir los datos en rojo',
  MSG_INCOMPLETE_CAPTCHA: 'Te falta marcar el check del Captcha',

}

export const LOGIN_NEXT_STEP = {
  CONTINUE: 'continue',
  LOGOUT: 'logout'
};

export const COUPON_TYPE = {
  AMOUNT: 'Monto',
  EXCHANGE_RATE: 'Tipo de cambio',
  COMMISSION: 'Comisión'
}

export enum AlertTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum StorageList {
  RETRY_COUNTER = 'retryCounter',
}

export enum StatusRegister {
  INCOMPLETE = 'lead',
  PARTIAL = 'falta foto',
  COMPLETE = 'completo'
}
