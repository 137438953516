import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements OnInit {

  @ViewChild('inputEl') inputEl: ElementRef;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() maxlength?: number;
  @Input() required: boolean = false;
  @Input() message: string = '';
  /**
   * @description valor permitido: ''(vacío)/'error'
   */
  @Input() state: string = '';
  /**
   * @description valores permitidos: text/number/date/datetime
   */
  @Input() type: string = 'text';

  @Output() ctrlKeyPress: EventEmitter<any> = new EventEmitter();
  @Output() ctrlChange: EventEmitter<any> = new EventEmitter();

  static nextId = 0;
  active: boolean = false;
  id: string;
  idHelp: string;
  formControl: FormControl = new FormControl();
  onChange = (value: any) => {};
  onTouched = () => {};

  constructor() {
    InputComponent.nextId++;
    this.id = `input-el-${InputComponent.nextId}`;
    this.idHelp = `input-help-${InputComponent.nextId}`;
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  writeValue(value: any): void {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getFocusEvent($event: any) {
    this.active = true;
  }

  getKeypressEvent($event: any) {
    this.ctrlKeyPress.emit({detail: $event});
  }

  getChangeEvent($event: any) {
    this.ctrlChange.emit({detail: $event});
  }

  getMaxLength() {
    return this.maxlength ? this.maxlength : 8;
  }
}
