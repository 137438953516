import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-bcp',
  templateUrl: './bcp.component.html',
  styleUrls: ['./bcp.component.scss']
})
export class BcpComponent {


  private router = inject(Router);
  private gtmService = inject(GtmService);
  
  goToRegister(accion) {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion,
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.REGISTER])
  }

  tagGotoViaBCP(accion): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion,
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);  
  }
}
