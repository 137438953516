<header translate="no" [ngClass]="{'border': border, 'sticky':sticky}">
    <div class="contain">
        <img class="full-screen header-logo" [src]="isLanding ? '/assets/images/landing/logo-header-white.png' : '/assets/images/logo-header.webp'" (click)="goToLanding()" alt="BCP logo" width="207" height="24">
        <img class="mobile header-logo" [src]="isLanding ? '/assets/images/logo.svg': '/assets/images/logo-header-responsive.svg'" (click)="goToLanding()" alt="BCP logo" width="84" height="22">
        <ul>
            <li *ngIf="isLanding">
                <p>
                    <a class="flexo-demi" href="javascript:;" href="https://wa.link/ny1jx2" target="_blank" rel="noopener">
                        <img src="assets/images/landing/whatsapp-icon.svg" class="whatsapp-icon" alt="Whatsapp" width="24" height="24" /><span class="full-screen">Contáctanos</span>
                    </a>
                </p>
            </li>
            <li *ngIf="isLanding">
                <p>
                    <a class="flexo-demi" href="javascript:;" (click)="goToFAQs()">
                        <img src="/assets/images/q_ic_question_circle.svg" class="question-icon" alt="Frequently Asked Questions" width="24" height="24" /><span class="full-screen">Preguntas frecuentes</span>
                    </a>
                </p>
            </li>
            <li>
                <app-button *ngIf="showLoginButton" shape="rectangle" tier="secondary" size="md" full-width="true" [mode]="isLanding? 'dark': 'light'" (ctrlClick)="goToLogin()">Inicia sesi&oacute;n</app-button>
            </li>
            <li>
                <app-button *ngIf="showRegisterButton" shape="rectangle" tier="primary" size="md" full-width="true" (ctrlClick)="goToRegister()">Reg&iacute;strate</app-button>
            </li>
        </ul>
    </div>
</header>
