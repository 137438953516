<section class="wp-banner">
    <img src="assets/images/whatsapp-image.svg" class="wp-banner-piggy" alt="Whatsapp" width="72" height="64" loading="lazy">
    <div class="wp-banner-content d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-end">
        <p class="flexo-demi font-size-14 font-size-md-16 mb-2 mb-md-0 mr-md-2">No te quedes con dudas</p>
        
        <a  (click)="tagGotoWhatsapp('Landing_escribenos​')"  class="d-flex align-items-center" [href]="whatsappUrl" rel="noopener" target="_blank">
            <img src="/assets/images/landing/whatsapp-icon.svg" width="24" height="24" alt="Whatsapp">
            <span class="ml-2 text-light font-size-14">Escríbenos</span>
        </a>
    </div>
</section>
