import { Component, OnInit, inject} from '@angular/core';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent {

  whatsappUrl = "https://wa.me/51945657689?text=Hola%20Mandadito%2C%20quiero%20saber%20informaci%C3%B3n%20sobre%E2%80%A6"
  private gtmService = inject(GtmService);

  tagGotoWhatsapp(accion): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion,
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);  
  }

}
