<app-header [border]="false" [sticky]="true" [isLanding]="true"></app-header>
<app-headband (showModal)="setBanner(true)"></app-headband>
<app-layer-popup-landing [isOpen]="showBanner" (closeModal)="setBanner(false)"></app-layer-popup-landing>
<div class="hero">
    <section class="send">
        <div class="first">
            <img src="/assets/images/landing/logo-dark.svg" alt="Header BCP logo" class="logo full-screen">
            <p class="title">Envía dinero de Florida a Perú</p>
            <div class="firstSend">
                <img src="/assets/images/landing/gift@2x.webp" alt="Gift" width="36" height="36">
                <span class="subtitle"> Primer envío ¡GRATIS! </span>
                <img src="/assets/images/landing/gift@2x.webp" alt="Gift" width="36" height="36">
            </div>
            <div class="solution full-screen">
                <a (click)="tagGotoSolutions('Landing_Solucion_bcp​')" href="#bcpElement"><span>Somos una solución del</span></a> <img src="/assets/images/landing/bcp-lg-color.svg" alt="BCP light logo">
            </div>
            <div class="button">
                <app-button shape="rectangle" tier="primary" size="lg" full-width="true" (ctrlClick)="goToRegister('Registrate_1')">Reg&iacute;strate y envía ahora</app-button>
            </div>
        </div>
        <article class="second">
            <app-currency-conversion-calculator (showTariffModalEvent)="showModalTariff = true"></app-currency-conversion-calculator>
        </article>
        <div class="solution mobile">
            <a (click)="tagGotoSolutions('Landing_Solucion_bcp​')" href="#bcpElement"><span>Somos una solución del</span></a> <img src="/assets/images/landing/bcp-lg-color.svg" alt="BCP light logo">
        </div>
    </section>

    <section class="howWork">
        <article class="first">
            <h2>Realiza tu primer envío en 4 simples pasos</h2>
        </article>
        <article class="second">
            <app-carousel-slide [items]="itemsCarousel" [activeArrows]="true"></app-carousel-slide>
            <br>
            <div class="delivery-schedule d-flex position-relative">
                <img src="/assets/images/spc_clock_hand_d_l@3x.svg" width="44" height="44" alt="Hand Calendar" loading="lazy">
                <div class="d-flex flex-column justify-content-between">
                    <p class="font-size-16 text-left font-size-md-20 flexo-demi line-height-24 mb-0 full-screen">¡Same day delivery! para Mandaditos solicitados antes de las 3 p.m. EST*</p>
                    <p class="font-size-16 text-left font-size-md-20 flexo-demi line-height-24 mb-0 mobile">¡Same day delivery! para<br> Mandaditos solicitados antes de<br> las 3 p.m. EST*</p>
                    <span class="font-size-10 font-size-md-12">*Válido para envíos aprobados hechos de lunes a viernes, sábado hasta la 2 p.m. Tiempo máximo de envío 1 día hábil.</span>
                </div>
            </div>
            <!--  -->
        </article>
    </section>
</div>

<section class="tariff">
    <div class="content">
        <div class="promo">
            <h2>
                <span>¡Tu primer envío con $0 fee!</span>
            </h2>
            <a class="tyc" href="/terminos-y-condiciones/campanias-activas/comision-cero">Términos y condiciones de promoción</a>
            <div class="button">
                <app-button shape="rectangle" tier="primary" size="lg" full-width="true"
                    (ctrlClick)="goToRegister('Registrate_4')">Reg&iacute;strate ahora</app-button>
            </div>  
        </div>  
    </div>
</section>

<section class="tariff-table">
    <div class="container">
            <span>Envía dinero a Perú desde $3.99</span>
            <p>A partir del segundo envío</p>
        <table>
            <thead>
                <th  style="text-align: left;">Monto USD</th>
                <th  style="text-align: right;">Comisión</th>
            </thead>
            <tbody>
                <tr *ngFor="let card of tariffCards" >
                    <td style="text-align: left;"  ><p1 [innerHTML]="card.title"></p1></td>
                    <td style="text-align: right;" ><p1 class="flexo-demi">{{card.tariff}}</p1></td>
                </tr>
            </tbody>
        </table>
    </div>
</section>

<app-carousel [slideInterval]="6000" [responsive]="true" class="d-block d-md-none"></app-carousel>
<app-carousel [slideInterval]="6000" class="d-none d-md-block"></app-carousel>

<div #bcpElement id="bcpElement">
    <app-bcp></app-bcp>
</div>

<div class="bg-white w-100 pt-5 pb-5">
    <h2>¿Tienes consultas?</h2> 
    <p style="text-align: center;">Nosotros te ayudamos a resolverlas</p>
    <div class="mt-4">
        <app-accordion [activeFirst]="false">
            <app-accordion-item *ngFor="let element of answerQuestions; let e = index" [title]="element.title" [index]="e"><!-- (toggleAccordion)="toggleAccordion($event, e+1)"-->
                <p [innerHTML]="element.text"></p>
            </app-accordion-item>
        </app-accordion>
    </div>
    <p (click)="goToFAQs()" class="cursor-pointer my-3 py-3 mx-auto justify-content-center d-flex align-items-center">
        <span class="flexo-demi secondary-500">Ver más preguntas</span>
        <img class="ml-2" src="/assets/images/right-arrow.svg" width="18" height="12" alt="Right arrow">
    </p>
<app-whatsapp></app-whatsapp>
</div>
<app-footer></app-footer>

<app-modal [size]="'lg'" *ngIf="showModalTariff">
    <section>
        <div class="close" (click)="showModalTariff = false;">
            <img src="/assets/images/close.svg" width="20" height="20" alt="Close modal" />
        </div>
        <div class="tariff">
            <h3 class="text-center">¿Cuánto cuesta Mandadito?</h3>
            <p class="text-center">Cobramos una comisión de acuerdo al monto que envíes</p>
            <div class="table">
                <ul class="head">
                        <li>Monto</li>
                        <li>Comisión</li>
                </ul>
                <div class="body">
                    <ul class="even">
                        <li>Hasta 99.99 USD</li>
                        <li>3.99 USD</li>
                    </ul>
                    <ul>
                        <li>100 - 199.99 USD</li>
                        <li>5.50 USD</li>
                    </ul>
                    <ul class="even">
                        <li>200 - 500.99 USD</li>
                        <li>7.50 USD</li>
                    </ul>
                    <ul>
                        <li>501 - 970 USD</li>
                        <li>9.00 USD</li>
                    </ul>
                </div>
            </div>
            <div class="button">
                <app-button shape="rectangle" tier="primary" size="lg" full-width="true"
                    (ctrlClick)="showModalTariff = false">De acuerdo</app-button>
            </div>
        </div>
    </section>
</app-modal>
