<app-search-by-dni (sendExchangeRate)="changeDni($event)" ></app-search-by-dni>
<br *ngIf="!showcomplete">
<div class="calculator"> 
    <div class="calculator-body" [ngClass]="showcomplete ? 'modeenabled' : 'modedisabled'">
        <div class="first-input">
            <div>
                <img src="/assets/images/usa-flag.png" alt="USA Flag" width="21" height="20">
                <span>USD</span>
            </div>
            <label for="first-input">Tu envías</label>
            <input type="text" id="first-input" [(ngModel)]="firstInputValue" pattern="^\d+(,\d+)*$" [disabled]="!showcomplete"
                (input)="onFirstInputChange($event)" [value]="firstInputValue" (blur)="onFirstInputBlur($event)"  placeholder="0.00"
                inputmode="decimal" lang="en" #firstInput>
            <span>Envía hasta $970 en un solo Mandadito</span>
        </div>

        <div class="second-input">
            <label for="second-input">Ellos reciben</label>
            <div class="custom-input">
                <input type="text" id="second-input" [(ngModel)]="secondInputValue" [value]="secondInputValue" [disabled]="!showcomplete"
                    (input)="onSecondInputChange($event)" (blur)="onSecondInputBlur($event)" inputmode="decimal" placeholder="0.00"
                    #secondInput>
                <div class="select-wrapper" [attr.data-selected-currency]="this.selectedCurrency">
                    <select name="currency" id="currency-selector" (input)="onSelectedCurrencyChange($event)" [disabled]="!showcomplete">
                        <option value="PEN">
                            PEN
                        </option>
                        <option value="USD">
                            USD
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="exchange-rate"  [ngClass]="!hasOperationValid ? 'left-exchange' : ''" *ngIf="this.selectedCurrency === 'PEN'"> 1 USD = {{exchangeRate}} PEN <span *ngIf="!hasOperationValid">Tipo de cambio especial</span></div>

        <div class="commission">
            <div class="flex-start">
                <div>Comisión <span *ngIf="!hasOperationValid">GRATIS</span></div>
                <div (click)="showTariffModal()">Ver tarifario</div>
            </div>
            <div class="flex-end">
                <span *ngIf="!hasOperationValid">$0.00</span>
                <span *ngIf="hasOperationValid && calculateCommission() === 0 ">Por calcular</span>
                <span>{{calculateCommission() === 0 ? '' : '$'+calculateCommission().toFixed(2)}}</span>
            </div>
        </div>

        <div class="button mt-4">
            <app-button *ngIf="!(showcomplete && hasOperationValid)" shape="rectangle" full-width="true" size="lg" (ctrlClick)="goToRegister()">Reg&iacute;strate y env&iacute;a ahora</app-button>
            <app-button *ngIf="showcomplete && hasOperationValid" shape="rectangle" full-width="true" size="lg" (ctrlClick)="goToLogin()">Env&iacute;a ahora</app-button>
        </div>
    </div>
</div>
