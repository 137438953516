import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  /** 
    @description Tamaño del botón, valores permitidos: 'lg'/'md'/'sm'
  */
  @Input() size: string = 'md';
  /** 
    @description Indica si es un botón primario o secundario, valores permitidos: 'primary'/'secondary'
  */
  @Input() tier: string = 'primary';
  /**
    @description Tema del botón, valores permitidos: 'dark'/'light' 
  */
  @Input() mode: string = 'dark'
  @Output() ctrlClick: EventEmitter<CustomEvent<any>> = new EventEmitter();

  constructor() { }
}
