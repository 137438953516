<div class="mt-4">
    <p class="flexo-demi">{{ data.title }}</p>
    <div class="custom-fileupload">
        <div (click)="triggerFileInput()">
            <div class="image">
                <img [ngClass]="{'d-none d-md-inline': image.status === null }" [src]="imgSpotCardAdd" alt="cargar desktop">
                <img [ngClass]="{'d-md-none d-inline': image.status === null }" [src]="imgSpotCamera" alt="cargar mobile">
                <img [ngClass]="{'d-block': image.status === 'success' }" src="/assets/images/avatar-square-icon-success.svg" alt="éxito">
                <img [ngClass]="{'d-block': image.status === 'error' }" src="/assets/images/avatar-square-icon-error.svg" alt="error">
            </div>
            <div class="text">
                <p class="flexo-demi">
                    <span class="d-none d-md-inline" *ngIf="image.status === null">Subir foto aqu&iacute;</span>
                    <span class="d-md-none d-inline" *ngIf="image.status === null">Click aqu&iacute; para tomar foto</span>
                    <span *ngIf="image.status !== null">{{ image.name.trim() }}</span>
                </p>
                <span class="d-none d-md-inline" *ngIf="image.status === null">{{ data.permitFormats.title }} {{ data.maxSize }}MB</span>
                <span class="d-md-none d-inline" *ngIf="image.status === null">{{ data.permitFormats.titleMobile }}</span>
                <span class="success flexo-demi" *ngIf="image.status === 'success'">¡Éxito!</span>
                <span class="error flexo-demi" *ngIf="image.status === 'error'">{{ errorImageType === ErrorImageType.FILE_TYPE ? 'Sube una imagen JPG o PNG' : 'La imagen sobrepasa los '+data.maxSize+'MB' }}</span>
            </div>
        </div>
        <img class="delete" src="/assets/images/icon-trashcan-delete.svg" (click)="removeImage()" *ngIf="image.status !== null" alt="">
        <input type="file" accept="image/jpg, image/png, image/jpeg" (change)="onFileChange($event)" #inputFile>
    </div>
</div>

<app-modal [size]="'sm'" *ngIf="showDocument">
    <section class="upload-file-modal"> <!-- change class from the general 'error-modal' to 'upload-file-modal' due to inconsistent styles behavior when selecting DNI or DL in registration step 2 -->
    <div class="contents">
        <h3 class="title original-size flexo-demi">Revisa la foto que 
            <span class="d-none d-md-inline">subiste</span>
            <span class="d-md-none d-inline">tomaste</span>
        </h3>
        <div class="preview-image">
            <img [src]="image.url" alt="">
        </div>
        <div class="info-box">
            <img src="/assets/images/e_ic_exclamation_triangle.svg" alt="Exclamation">
            <span>Recuerda que tu documento debe estar vigente</span>
        </div>
        <p class="dni-text">Asegúrate que cumpla los requisitos:</p>
        <ul class="checks-document">
            <ng-container *ngIf="selectedDocument === documentTypes.DNI">
                <li>Las fotos <strong>no deben estar borrosas</strong></li>
                <li>En la foto <strong>el DNI debe estar completo</strong>, no cortado</li>
            </ng-container>
            <ng-container *ngIf="selectedDocument === documentTypes.DriverLicense">
                <li><strong>Driver's License emitido en USA</strong></li>
                <li>Las fotos <strong>no deben estar borrosas</strong></li>
                <li>En la foto tu <strong>Driver's License debe estar completo</strong>, no cortado</li>
            </ng-container>
        </ul>

        <div class="d-flex flex-column document-buttons mx-auto mt-3">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="photoIsOk()">La foto cumple los requisitos</bcp-button>
            <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="removeImage()">
                <span class="d-none d-md-inline">Subir</span>
                <span class="d-md-none d-inline">Tomar</span> otra foto
            </bcp-button>
        </div>
    </div>
    </section>
</app-modal>
